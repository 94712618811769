import * as React from "react";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";

export default function CustomTimePicker({ value, onChange, ...props }) {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <MobileTimePicker
        className="custom-time-picker"
        sx={{
          '&.custom-time-picker .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd':{padding: '8px 14px !important',}
        }}
        value={value}
        onChange={onChange}
        renderInput={(params) => <TextField
        
           {...params} />}
        {...props}
      />
    </LocalizationProvider>
  );
}
