import React from "react";
import HeaderWrapper from "./header.style";
import { UncontrolledPopover, PopoverBody } from "reactstrap";
import { ProfileLockScreen } from "helper/constant";
import { connect } from "react-redux";
import { compose } from "redux";
import AuthActions from "redux/auth/actions";
import { withRouter } from "react-router-dom";
// import { Bell } from "react-feather";
// import moment from "moment";
// import PopoverBlock from "./PopoverBlock";

const { logout, setUser } = AuthActions;

const Header = props => {
  const {
    drawerMiniMethod,
    mini,
    token,
    // notificationData,
    // userNotifications,
    // notifications,
    // getNotificationData
  } = props;
  // console.log(notificationData, "check18");
  // const [notificationpopoverOpen, setNotificationPopoverOpen] = useState(false);
  // const notificationtoggle = () =>
  //   setNotificationPopoverOpen(!notificationpopoverOpen);
  // let unreadCount = notificationData?.unread_notifications;

  const userSignout = () => {
    props.setUser({});
    props.logout(token);
  };

  return (
    <HeaderWrapper {...props}>
      <div className="headerBack">
        <div className="flex-x align-center">
          <div className="drawer-handle-arrow">
            {mini ? (
              <button
                className="top-header-icon"
                onClick={() => drawerMiniMethod()}
              >
                <i className="fas fa-bars"></i>
              </button>
            ) : (
              <button
                className="top-header-icon"
                onClick={() => drawerMiniMethod()}
              >
                <i className="fas fa-bars"></i>
              </button>
            )}
          </div>
          <div
            className="mini-drawer-menu-icon"
            onClick={() => drawerMiniMethod()}
          >
            <i className="fas fa-bars" />{" "}
            <span className="app-name fs-16 bold-text">{"Roe"}</span>
          </div>
          
          <div className="pl-10 flex-1">
            
          </div>
          
          {/* <div className="mr-10">
            <div id="notification" style={{ cursor: "pointer" }}>
              <button
                id="notification"
                className="top-header-icon"
                style={{ backgroundColor: "transparent" }}
              >
                <Bell color="#000" onClick={() => userNotifications()} />
                {unreadCount ? (
                  <div className="button-badge fs-11 demi-bold-text">
                    {unreadCount}
                  </div>
                ) : (
                  <></>
                )}
              </button>
            </div>
            <UncontrolledPopover
              className="header-popover"
              innerClassName="roy-inner-content"
              placement="bottom-end"
              target="notification"
              trigger="legacy"
              isOpen={notificationpopoverOpen}
              toggle={notificationtoggle}
            >
              <PopoverBody className="mail-popover-body">
                <div className="fs-13 bold-text mb-10">
                  You{" "}
                  {unreadCount === 0 ? "don't have " : `have ${unreadCount} `}
                  new Notifications.
                </div>
                <div style={{ overflow: "auto", maxHeight: "350px" }}>
                  {notifications?.length > 0 &&
                    notifications?.map((i, index) => {
                      return (
                        <PopoverBlock
                          title={i.n_title}
                          text={i.n_message}
                          created={moment(i.createdAt).format(
                            "DD-MM-YYYY hh:mm a"
                          )}
                          link={i.n_link}
                          id={i._id}
                          token={token}
                          seen={i.seen}
                          key={`notifications_${index}`}
                          getNotificationData={getNotificationData}
                          userNotifications={userNotifications}
                          setNotificationPopoverOpen={
                            setNotificationPopoverOpen
                          }
                        />
                      );
                      
                    })}
                  
                </div>
              </PopoverBody>
            </UncontrolledPopover>
          </div> */}
          <div className="pl-10">
            <div id="profile" className="cursor-pointer">
              <img
                className="top-header-profile-class"
                src={ProfileLockScreen}
                alt="notify"
              />
            </div>
            <UncontrolledPopover
              className="roy-menu"
              innerClassName="roy-inner-content"
              placement="bottom-end"
              target="profile"
              trigger="legacy"
            >
              <PopoverBody>
                <div
                  className="roy-menu-list"
                  onClick={() => props.history.push("/profile")}
                >
                  My Profile
                </div>
                <div
                  className="roy-menu-list"
                  onClick={() => props.history.push("/change-password")}
                >
                  Change Password
                </div>
                <div className="roy-menu-list" onClick={userSignout}>
                  Logout
                </div>
              </PopoverBody>
            </UncontrolledPopover>
          </div>
         
        </div>
      </div>
    </HeaderWrapper>
  );
};

const mapStateToProps = state => {
  return {
    token: state.auth.accessToken,
    notificationData: state.navigation.notificationData
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, { logout, setUser })
)(Header);
