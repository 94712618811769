import { api, handleResponse, handleError } from "./apiServices";
const baseUrl = "api/grahanDay";

export const getAllGrahanDays = (token, data) =>
  api(token)
    .post(`${baseUrl}/getList`,data)
    .then(handleResponse)
    .catch(handleError);

export const addGrahanDay = (token, data) =>
  api(token)
    .post(`${baseUrl}/add`, data)
    .then(handleResponse)
    .catch(handleError);

export const editGrahanDay = (token, id, data) =>
  api(token)
    .put(`${baseUrl}/edit/${id}`, data)
    .then(handleResponse)
    .catch(handleError);

export const deleteGrahanDay = (token, id) =>
  api(token)
    .delete(`${baseUrl}/delete/${id}`)
    .then(handleResponse)
    .catch(handleError);