import { connect } from "react-redux";
import React, { useState } from "react";
import { Button, ModalHeader, ModalBody } from "reactstrap";
import { compose } from "redux";
import NavigationActions from "redux/navigation/actions";
import Loader from "components/Loader";
import panchangDaySchema from "./validationSchema";
import Papa from "papaparse";
import PanchangPreviewTable from "./PanchangPreviewTable";
import { addBulkPanchangDay } from "services/panchangDayServices";

const { success, error } = NavigationActions;
const ImportEvents = ({ onClose, token }) => {
  const [loading, setLoading] = useState(false);
  const [previewData, setPreviewData] = useState([]);
  const [errors, setErrors] = useState([]);

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    setLoading(true);
    try {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: async (results) => {
          const { data } = results;
          const validationErrors = [];
          const validData = [];

          for (const [index, row] of data.entries()) {
            try {
              // Validate each row using Yup schema

              const gregorianDate =  new Date(row.gregorianDate).toISOString().split('T')[0];

              // Transform `event.english` and `event.gujarati` into a nested `event` object
              const transformedRow = {
                // ...row,
                gregorianDate,
                event: {
                  english: row["event.english"] || "",
                  gujarati: row["event.gujarati"] || "",
                },
              };

              // Remove unused keys from the original row
              delete transformedRow["event.english"];
              delete transformedRow["event.gujarati"];

              const validatedRow = await panchangDaySchema.validate(transformedRow, {
                abortEarly: false,
              });
              validData.push(validatedRow);
            } catch (validationError) {
              console.error("Error validating CSV:", validationError);

              validationErrors.push({
                row: index + 1,
                errors: validationError.errors,
              });
            }
          }

          setPreviewData(validData);
          setErrors(validationErrors);
        },
      });
    } catch (error) {
      console.error("Error parsing CSV:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    if (errors.length > 0) {
      error("Please fix errors before submitting.");
      return;
    }

    try {
      // Send the valid data to the server for bulk upload
      const res = await addBulkPanchangDay(token, {data:previewData});
      if (res?.success) {
        success(res?.message);
        onClose(true);
      } else {
        error(res?.message);
      }
    } catch (err) {
      console.error(err);
      alert("Error uploading data.");
    }
  };
  
  return (
    <>
      <ModalHeader toggle={() => onClose()}>
        Import CSV File for Bulk Events
      </ModalHeader>
      <ModalBody>
        <div>
          <input type="file" accept=".csv" onChange={handleFileUpload} />
          <Button
            onClick={handleSubmit}
            className="btn form-button modaladdbutton"
            disabled={loading || errors.length > 0}
          >
            {loading ? "Uploading..." : "Upload"}
          </Button>

          <div>
            {previewData?.length > 0 && (
              <>
                <h4>Preview</h4>
                <PanchangPreviewTable data={previewData} />
              </>
            )}
            {errors?.length > 0 && (
              <div>
                <h4>Errors</h4>
                {errors.map((err) => (
                  <p key={err.row}>
                    Row {err.row}: {err.errors.join(", ")}
                  </p>
                ))}
              </div>
            )}
          </div>
        </div>
      </ModalBody>
      {loading ? <Loader /> : ""}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state.themeChanger,
    token: state.auth.accessToken,
  };
};

export default compose(connect(mapStateToProps, { success, error }))(
  ImportEvents
);
