import { api, handleResponse, handleError } from "./apiServices";
const baseUrl = "api/patotsav";

export const getAllPatotsav = (token) =>
  api(token)
    .get(`${baseUrl}/getList`)
    .then(handleResponse)
    .catch(handleError);

export const addPatotsav = (token, data) =>
  api(token)
    .post(`${baseUrl}/addPatotsav`, data)
    .then(handleResponse)
    .catch(handleError);

export const editPatotsav = (token, id, data) =>
  api(token)
    .put(`${baseUrl}/editPatotsav/${id}`, data)
    .then(handleResponse)
    .catch(handleError);

export const deletePatotsav = (token, id) =>
  api(token)
    .delete(`${baseUrl}/deletePatotsav/${id}`)
    .then(handleResponse)
    .catch(handleError);

export const bulkUploadPatotsav = (token, data) =>
  api(token)
    .post(`${baseUrl}/bulkUpload`, data)
    .then(handleResponse)
    .catch(handleError);
