import { connect } from "react-redux";
import React, { useState, useMemo, useEffect, useCallback } from "react";
import { useTable, useSortBy, useFilters, usePagination } from "react-table";
import classnames from "classnames";
import ReactTableWrapper from "../../../components/reacttable/reacttbl.style";
import Pagination from "@mui/material/Pagination";

import { compose } from "redux";
import NavigationActions from "redux/navigation/actions";
import { Modal } from "reactstrap";
import ConformationModaluser from "components/common/ConformationModalUser";
import Loader from "components/Loader";
import { deleteGrahanDay,getAllGrahanDays } from "services/grahanDayServices";
import AddEditGrahan from "./AddEditGrahan";
import DatePicker from "react-datepicker";
import DatePickerWrapper from "components/common/DatePickerWrapper";
import moment from "moment";

const HeaderComponent = props => {
  let classes = {
    "my-2": true,
    "mx-2": true,
    "-sort-asc": props.isSortedDesc === false,
    "-sort-desc": props.isSortedDesc === true
  };
  return <div className={classnames(classes)}>{props.title}</div>;
};

const { success, error } = NavigationActions;

const GrahanList = props => {
  const { sidebarTheme, token } = props;
  const activeColor = {
    color: sidebarTheme.activeColor
  };

  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [refresh, toggleRefresh] = useState(true);
  const [openDeleteModal, toggleDeleteModalOpen] = useState();
  const [deleteId, setDeleteID] = useState("");
  const [grahanList, setGrahanList] = useState([]);
  const [search, setSearch] = useState();
  const [loading, setLoading] = useState(false);
  const [editData, setEditData] = useState({});
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const formatSearchDate = searchTerm => {
    const validDate = moment(searchTerm, ["DD-MM-YYYY", "DD/MM/YYYY", "YYYY-MM-DD"], true);
    return validDate.isValid() ? `${validDate.format("YYYY-MM-DD")}T00:00:00.000Z` : searchTerm;
  };

  const fetchAllGrahans = useCallback(async () => {
    try {
      setLoading(true);
      const payload = {
        page: currentPage,
        limit: 10,
        search: formatSearchDate(search),
      };
      const res = await getAllGrahanDays(token, payload);
      if (res?.success) {
        setGrahanList(res?.data.docs);
        setTotalPages(res?.data?.totalPages);
      } else {
        error(res?.message);
      }
    } catch (error) {
      console.log(error, "error");
    } finally {
      setLoading(false);
      toggleRefresh(false);
    }

  }, [token, currentPage, search]);


  const deleteGrahanHandler = useCallback(async () => {
    setLoading(true);
    try {
      const res = await deleteGrahanDay(token, deleteId)
      if (res?.success) {
        success(res?.message);
        toggleDeleteModalOpen(false);
        toggleRefresh(true);
        setDeleteID("");
        setCurrentPage(1);
      } else {
        error(res?.message);
      }
    } catch (error) {
      console.log(error, "error");
    } finally {
      setLoading(false);
    }
  },[token, deleteId]);

  const columns = useMemo(
    () => [
      {
        Header: (tableInstance) => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Grahan Date"
            />
          );
        },
        placeholder: "date",
        accessor: "date",
        Cell: (tableInstance) => (
            <span className="text-capitalize">
              {tableInstance.row.original?.date?.split('T')[0]}
            </span>
            
        ),
      },
      {
        Header: (tableInstance) => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Grahan Type"
            />
          );
        },
        placeholder: "Grahan Type",
        accessor: "grahanType",
        Cell: (tableInstance) => (
            <span className="text-capitalize">
              {tableInstance.row.original?.grahanType}
            </span>
            
        ),
      },
      {
        Header: (tableInstance) => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Start Time"
            />
          );
        },
        placeholder: "Start Time",
        accessor: "startTime",
        Cell: (tableInstance) => (
            <span className="text-capitalize">
              {tableInstance.row.original?.startTime}
            </span>
            
        ),
      },
      {
        Header: (tableInstance) => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="End Time"
            />
          );
        },
        placeholder: "End Time",
        accessor: "endTime",
        Cell: (tableInstance) => (
            <span className="text-capitalize">
              {tableInstance.row.original?.endTime}
            </span>
        ),
      },
      {
        Header: (tableInstance) => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Action"
            />
          );
        },
        accessor: "id",
        disableSortBy: true,
        disableFilters: true,
        Cell: (tableInstance) => {
          return (
            <div className="react-action-class">
              <button
                className="react-table-edit-button"
                onClick={() => {
                  setModal(true);
                  setIsEdit(true);
                  setEditData(tableInstance.row.original);
                }}
              >
                <i className="fas fa-edit" />
              </button>
              <button
                className="react-table-delete-button"
                onClick={() => {
                  toggleDeleteModalOpen(true);
                  setDeleteID(tableInstance.row.original?._id);
                }}
              >
                <i className="fas fa-trash" />
              </button>
            </div>
          );
        },
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    page,
    headerGroups,
  } = useTable(
    {
      data: grahanList,
      columns: columns,
      initialState: {
        pageSize: 10,
        pageIndex: 0,
      },
    },
    useFilters,
    useSortBy,
    usePagination
  );
// Effects
useEffect(() => {
  refresh && fetchAllGrahans();
}, [refresh, fetchAllGrahans]);

useEffect(() => {
  if (search !== undefined) {
    const timeoutId = setTimeout(() => fetchAllGrahans(), 1500);
    return () => clearTimeout(timeoutId);
  }
}, [search, fetchAllGrahans]);

  return (
    <>
      <div>
        <div className="plr-15">
          <div className="mtb-30 theme-color">
            <div className="row">
              <div className="col-6 col-md-8 col-lg-10">
                <div className="introduction" style={activeColor}>
                  Grahan
                </div>
              </div>
              <div className="col-6 col-md-4 col-lg-2">
                <div className="d-flex justify-content-center align-items-center">
                  <button
                    type="submit"
                    className="c-primary btn btn-secondary w-100 addbtncolor ml-2 text-nowrap"
                    onClick={() => {
                      setIsEdit(false);
                      setModal(true);
                    }}
                  >
                    Add Grahan
                  </button>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-2">
                <DatePickerWrapper>
                  <DatePicker
                    peekPriviousMonth
                    showMonthDropdown
                    showYearDropdown
                    placeholder="Search Grahan Date"
                    name="gregorianDate"
                    id="gregorianDate"
                    selected={search}
                    dateFormat="yyyy/MM/dd"
                    className="form-control react-form-input"
                    value={search}
                    onChange={(date) => {
                      setSearch(date);
                      setCurrentPage(1);
                    }}
                  />
                </DatePickerWrapper>
              </div>
              <div className="col-12">
                {
                  <ReactTableWrapper {...props}>
                    <div className="table-container text-left overflow-auto mt-4">
                      <table
                        border={1}
                        className="custom-react-table-theme-class"
                        {...getTableProps()}
                      >
                        <thead>
                          {headerGroups?.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                              {headerGroup.headers?.map((header) => (
                                <th
                                  {...header.getHeaderProps(
                                    header.getSortByToggleProps()
                                  )}
                                >
                                  <div>{header.render("Header")}</div>
                                </th>
                              ))}
                            </tr>
                          ))}
                        </thead>
                        {loading ? (
                          <tbody>
                            <tr>
                              <td>
                                <Loader />
                              </td>
                            </tr>
                          </tbody>
                        ) : (
                          <tbody {...getTableBodyProps()}>
                            {grahanList?.length > 0 ? (
                              page?.map((row) => {
                                prepareRow(row);
                                return (
                                  <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => (
                                      <td {...cell.getCellProps()}>
                                        {cell.render("Cell")}
                                      </td>
                                    ))}
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                {
                                  <td className="mt-4 text-left font-weight-bold">
                                    <h5>No data found</h5>
                                  </td>
                                }
                              </tr>
                            )}
                          </tbody>
                        )}
                      </table>
                    </div>
                    <div className="float-right">
                      <Pagination
                        page={currentPage}
                        count={totalPages}
                        onChange={(e, val) => {
                          setCurrentPage(val);
                          toggleRefresh(true);
                        }}
                      />
                    </div>
                  </ReactTableWrapper>
                }
              </div>
            </div>
          </div>
        </div>
        <Modal
          isOpen={modal}
          backdrop={true}
          centered={true}
          unmountOnClose={true}
        >
          {modal && (
            <AddEditGrahan
              isEdit={isEdit}
              token={token}
              editData={editData}
              onClose={() => {
                setModal(false);
                setIsEdit(false);
                setEditData(null);
              }}
              toggleRefresh={(e) => toggleRefresh(e)}
            />
          )}
        </Modal>

        <Modal isOpen={openDeleteModal} backdrop={true} unmountOnClose={true}>
          {openDeleteModal && (
            <ConformationModaluser
              isOpen={openDeleteModal}
              onClose={() => toggleDeleteModalOpen(false)}
              confirmText={"Delete"}
              message={"Are you sure you want to delete?"}
              handleConfirm={() => deleteGrahanHandler()}
            />
          )}
        </Modal>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    token: state.auth.accessToken
  };
};

export default compose(
  // enhancer,
  connect(mapStateToProps, { success, error })
)(GrahanList);
