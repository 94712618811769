import { connect } from "react-redux";
import React, { useState, useEffect, useCallback } from "react";
import { compose } from "redux";
import NavigationActions from "redux/navigation/actions";
import { Modal } from "reactstrap";
import Loader from "components/Loader";
import { getAboutUs } from "services/aboutServices";
import AddEditAbout from "./AddEditAbout";


const { success, error } = NavigationActions;

const AboutPage = props => {
  const { sidebarTheme, token } = props;
  const activeColor = {
    color: sidebarTheme.activeColor
  };

  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [refresh, toggleRefresh] = useState(true);
  const [aboutData, setAboutData] = useState({});
  const [loading, setLoading] = useState(false);
  const [editData, setEditData] = useState({});


  const fetchAboutUs = useCallback(async () => {
    try {
      setLoading(true);
      const res = await getAboutUs(token);
      if (res?.success) {
        setAboutData(res?.data[0]);
      } else {
        error(res?.message);
      }
    } catch (error) {
      console.log(error, "error");
    } finally {
      setLoading(false);
      toggleRefresh(false);
    }

  }, [token]);


  useEffect(() => {
    refresh && fetchAboutUs();
  }, [refresh]);



  return (
    <>
      <div>
        <div className="plr-15">
          <div className="mtb-30 theme-color">
            <div className="row">
              <div className="col-6 col-md-8 col-lg-10">
                <div className="introduction" style={activeColor}>
                  About Us
                </div>
              </div>
              <div className="col-6 col-md-4 col-lg-2">
                <div className="d-flex justify-content-center align-items-center">
                  <button
                    type="submit"
                    className="c-primary btn btn-secondary w-100 addbtncolor ml-2 text-nowrap"
                    onClick={() => {
                      setIsEdit(aboutData?._id ? true : false);
                      aboutData?._id && setEditData(aboutData);
                      setModal(true);
                    }}
                  >
                    {aboutData?._id ? "Edit" : "Add"} Details
                  </button>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 mt-3">
                {aboutData?.imageLogo && (
                  <div className="about-img">
                    <img
                      src={
                        process.env.REACT_APP_UPLOAD_DIR + aboutData?.imageLogo
                      }
                      alt="about"
                      className="img-fluid"
                    />
                  </div>
                )}
                <div className="about-content mt-3">
                  <h3 className="mb-3">{aboutData?.heading}</h3>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: aboutData?.description,
                    }}
                  />
                </div>
                <div className="social-links mt-3 d-flex">
                  <ul className="d-flex justify-content-center list-unstyled">
                    <li>
                      <a
                        href={aboutData.socialLinks?.facebook}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fab fa-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href={aboutData.socialLinks?.twitter}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fab fa-twitter" />
                      </a>
                    </li>
                    <li>
                      <a
                        href={aboutData.socialLinks?.instagram}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fab fa-instagram" />
                      </a>
                    </li>
                    <li>
                      <a
                        href={aboutData.socialLinks?.linkedin}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fab fa-linkedin" />
                      </a>
                    </li>
                    <li>
                      <a
                        href={aboutData.socialLinks?.youtube}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fab fa-youtube" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          isOpen={modal}
          backdrop={true}
          centered={true}
          unmountOnClose={true}
        >
          {modal && (
            <AddEditAbout
              isEdit={isEdit}
              token={token}
              editData={editData}
              onClose={() => {
                setModal(false);
                setIsEdit(false);
                setEditData(null);
              }}
              toggleRefresh={(e) => toggleRefresh(e)}
            />
          )}
        </Modal>
        {loading && <Loader />}
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    token: state.auth.accessToken
  };
};

export default compose(
  // enhancer,
  connect(mapStateToProps, { success, error })
)(AboutPage);
