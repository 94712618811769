import { connect } from "react-redux";
import React, { useState } from "react";
import { Button, ModalHeader, ModalBody } from "reactstrap";
import { compose } from "redux";
import NavigationActions from "redux/navigation/actions";
import Loader from "components/Loader";
import { useFormik } from "formik";
import { addPanchangDay, editPanchangDay } from "services/panchangDayServices";
// import {
//   VIKRAM_SAMVAT_MONTHS,
//   TITHI_NAMES,
//   RITU_NAMES,
//   NAKSHATRA_NAMES,
//   RASHI_NAMES,
// } from "helper/constant";
// import Select from "react-select";
import DatePicker from "react-datepicker";
// import moment from "moment";

// import CustomTimePicker from '../../../components/common/CustomTimePicker';
import panchangDaySchema from "./validationSchema";
import DatePickerWrapper from "components/common/DatePickerWrapper";
const { success, error } = NavigationActions;

const AddEditEvent = ({ isEdit, editData, onClose, token, toggleRefresh }) => {
  const [loading, setLoading] = useState(false);
  // const tithiOptions = Object.keys(TITHI_NAMES).map((key) => ({
  //   value: key,
  //   label: `${key} - ${TITHI_NAMES[key]}`,
  // }));
  // const monthOptions = VIKRAM_SAMVAT_MONTHS.map((val) => ({
  //   value: val,
  //   label: val,
  // }));
  // const nakshatraOptions = NAKSHATRA_NAMES.map((key) => ({
  //   value: key,
  //   label: key,
  // }));
  // const rituOptions = RITU_NAMES.map((key) => ({
  //   value: key,
  //   label: key,
  // }));

  // const rashiOptions = RASHI_NAMES.map((key) => ({
  //   value: key,
  //   label: key,
  // }));

  // const pakshaOptions = [
  //   {
  //     label: "Shukla",
  //     value: "Shukla",
  //   },
  //   {
  //     label: "Krishna",
  //     value: "Krishna",
  //   },
  // ];
  // console.log("moment",moment("06:00 AM", "hh:mm A"))
  // console.log("dayjs",moment("06:00 AM", "hh:mm A"))
  const formik = useFormik({
    initialValues: isEdit
      ? {
          ...editData,
          gregorianDate: new Date(editData.gregorianDate),
          // sunrise: moment(editData.sunrise, "hh:mm A"),
          // sunset: moment(editData.sunset, "hh:mm A"),
          // moonRashiTime: moment(editData?.moonRashiTime, "hh:mm A") || "",
        }
      : {
          gregorianDate: new Date(),
          // vikramSamvatYear: 2081,
          // vikramSamvatMonth: "",
          // tithi: "",
          // paksha: "",
          // ritu: "",
          // sunrise: moment("06:00 AM", "hh:mm A"),
          // sunset: moment("07:00 PM", "hh:mm A"),
          // nakshatra: "",
          // image: "",
          // moonRashi: "",
          // moonRashiTime: "",
          event: {
            english:'',
            gujarati:'',
          },
        },
    validationSchema: panchangDaySchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);

        const payload = {
          gregorianDate: values.gregorianDate.toISOString(),
          event:values.event,
          // sunrise: values.sunrise.format("hh:mm A"),
          // sunset: values.sunset.format("hh:mm A"),
        };
        // if(values.moonRashiTime) {
        //   payload.moonRashiTime = values.moonRashiTime.format("hh:mm A");
        // }
        
        // convert payload to formData
        const formData = new FormData();
        for (const key in payload) {
          if (payload.hasOwnProperty(key)) {
            if (key === "event") {
              // console.log("event", payload[key]);
              formData.append(key, JSON.stringify(payload[key]));
            } else {
              formData.append(key, payload[key]);
            }
          }
        }

        // console.log("Submitting payload:", payload);

        const res = isEdit
          ? await editPanchangDay(token, values?._id, formData)
          : await addPanchangDay(token, formData);
        if (res?.success) {
          success(res?.message);
          onClose();
          toggleRefresh(true);
        } else {
          error(res?.message);
        }
      } catch (error) {
        error(error.message);
      } finally {
        setLoading(false);
      }
    },
  });

  const Error = (props) => {
    const field1 = props.field;
    if ((formik.errors[field1] && formik.touched[field1]) || formik.submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {formik.errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  const {
    values,
    handleChange,
    handleBlur,
  } = formik;

  console.log(formik?.values);
  
  return (
    <>
      <ModalHeader toggle={() => onClose()}>
        {isEdit ? "Edit" : "Add"} Event
      </ModalHeader>
      <ModalBody>
        <form onSubmit={formik.handleSubmit}>
          <div className="form-group">
            <label>
              Image
            </label>
            <br />

            <input
              type="file"
              id="upload"
              className="form-control react-form-input"
              accept=".jpg,.png,.jpeg,.svg"
              onChange={(e) => {
                if (e.target.files[0]) {
                  formik.setFieldValue("image", e.target.files[0]);
                } else {
                  formik.setFieldValue("image", "");
                }
              }}
              onBlur={handleBlur}
            />
            {values?.image && (
              <>
                <br />
                <img
                  src={
                    values.image instanceof Blob
                      ? URL.createObjectURL(values.image)
                      : `${process.env.REACT_APP_UPLOAD_DIR}${values?.image}`
                  }
                  className="profile-img"
                  alt="no"
                  width={200}
                />
                <br />
              </>
            )}
            <Error field="image" />
          </div>
          <div className="form-group">
            <label>
              Date <span className="red">*</span>
            </label>
            <DatePickerWrapper>
              <DatePicker
                peekPriviousMonth
                showMonthDropdown
                showYearDropdown
                name="gregorianDate"
                id="gregorianDate"
                selected={values.gregorianDate}
                dateFormat="yyyy/MM/dd"
                className="form-control react-form-input"
                value={values.gregorianDate}
                onChange={(date) => {
                  formik.setFieldValue("gregorianDate", date);
                }}
                onBlur={handleBlur}
              />
            </DatePickerWrapper>
            <Error field="gregorianDate" />
          </div>

          {/* <div className="form-group d-flex mb-0">
            <div className="form-group mr-2 w-50">
              <label>
                Vikram Samvat Year <span className="red">*</span>
              </label>
              <input
                type="number"
                className="form-control react-form-input"
                id="vikramSamvatYear"
                onChange={handleChange}
                value={values.vikramSamvatYear}
                onBlur={handleBlur}
                placeholder="Vikram Samvat Year"
              />
              <Error field="vikramSamvatYear" />
            </div>
            <div className="ml-2 form-group ml-2 w-50">
              <label>
                Vikram Samvat Month <span className="red">*</span>
              </label>
              <Select
                value={monthOptions.find(
                  (item) => item.value === values.vikramSamvatMonth
                )}
                name="vikramSamvatMonth"
                options={monthOptions}
                className="basic-multi-select w-100"
                classNamePrefix="select"
                onChange={(selected) => {
                  formik.setFieldValue("vikramSamvatMonth", selected.value);
                }}
              />

              <Error field="vikramSamvatMonth" />
            </div>
          </div>

          <div className="form-group d-flex mb-0">
            <div className="form-group mr-2 w-50">
              <label>
                Tithi <span className="red">*</span>
              </label>
              <Select
                value={tithiOptions.find((item) => item.value === values.tithi)}
                name="tithi"
                options={tithiOptions}
                className="basic-multi-select w-100"
                classNamePrefix="select"
                onChange={(selected) => {
                  formik.setFieldValue("tithi", selected.value);
                  switch (selected.value) {
                    case "Purnima":
                      formik.setFieldValue("paksha", "Shukla");
                      break;
                    case "Amavasya":
                      formik.setFieldValue("paksha", "Krishna");
                      break;
                    default:
                      break;
                  }
                }}
              />
              <Error field="tithi" />
            </div>
            <div className="ml-2 form-group w-50">
              <label>
                Paksha <span className="red">*</span>
              </label>
              <Select
                isDisabled={
                  values.tithi === "Purnima" || values.tithi === "Amavasya"
                }
                value={pakshaOptions.find((i) => i.value === values.paksha)}
                name="paksha"
                options={pakshaOptions}
                className="basic-multi-select w-100"
                classNamePrefix="select"
                onChange={(selected) => {
                  formik.setFieldValue("paksha", selected.value);
                }}
              />
              <Error field="paksha" />
            </div>
          </div>

          <div className="form-group d-flex mb-0">
            <div className="form-group mr-2 w-50">
              <label>
                Sunrise Time <span className="red">*</span>
              </label>
              <CustomTimePicker
                value={formik.values.sunrise}
                onChange={(value) => {
                  formik.setFieldValue("sunrise", value);
                }}
                name="sunrise"
                disabled={false}
                minTime={moment("06:00 AM", "hh:mm A")}
                maxTime={moment("08:00 AM", "hh:mm A")}
              />
              <Error field="sunrise" />
            </div>

            <div className="ml-2 form-group w-50">
              <label>
                Sunset Time <span className="red">*</span>
              </label>
              <CustomTimePicker
                value={formik.values.sunset}
                onChange={(value) => {
                  formik.setFieldValue("sunset", value);
                }}
                name="sunset"
                disabled={false}
                minTime={moment("06:00 PM", "hh:mm A")}
                maxTime={moment("08:00 PM", "hh:mm A")}
              />
              <Error field="sunset" />
            </div>
          </div>

          <div className="form-group d-flex mb-0">
            <div className="form-group mr-2 w-50">
              <label>
                Ritu
              </label>
              <Select
                value={rituOptions.find((item) => item.value === values.ritu)}
                name="ritu"
                options={rituOptions}
                className="basic-multi-select w-100"
                classNamePrefix="select"
                onChange={(selected) => {
                  formik.setFieldValue("ritu", selected.value);
                }}
              />
              <Error field="ritu" />
            </div>
            <div className="ml-2 form-group w-50">
              <label>
                Nakshatra
              </label>
              <Select
                value={nakshatraOptions.find(
                  (i) => i.value === values.nakshatra
                )}
                name="nakshatra"
                options={nakshatraOptions}
                className="basic-multi-select w-100"
                classNamePrefix="select"
                onChange={(selected) => {
                  formik.setFieldValue("nakshatra", selected.value);
                }}
              />
              <Error field="nakshatra" />
            </div>
          </div>

          <div className="form-group d-flex mb-0">
            <div className="form-group mr-2 w-50">
              <label>
                Moon in Rashi
              </label>
              <Select
                value={rashiOptions.find((item) => item.value === values.moonRashi)}
                name="moonRashi"
                options={rashiOptions}
                className="basic-multi-select w-100"
                classNamePrefix="select"
                onChange={(selected) => {
                  formik.setFieldValue("moonRashi", selected.value);
                }}
              />
              <Error field="moonRashi" />
            </div>
            <div className="ml-2 form-group w-50">
              <label>
                  Moon in Rashi Time
                </label>
                <CustomTimePicker
                  value={formik.values?.moonRashiTime ? moment(formik.values?.moonRashiTime) : null}
                  onChange={(value) => {
                    formik.setFieldValue("moonRashiTime", value);
                  }}
                  name="moonRashiTime"
                  disabled={false}
                />
                <Error field="moonRashiTime" />
            </div>
          </div> */}

          <div className="form-group">
            <label>
              Event Details Gujarati <span className="red">*</span>
            </label>
            <textarea
              className="form-control react-form-input"
              id="event.gujarati"
              rows={3}
              onChange={handleChange}
              value={values?.event?.gujarati}
              onBlur={handleBlur}
              placeholder="Event Details"
            />
            <Error field="event.gujarati" />
          </div>

          <div className="form-group">
            <label>
              Event Details English <span className="red">*</span>
            </label>
            <textarea
              className="form-control react-form-input"
              id="event.english"
              rows={3}
              onChange={handleChange}
              value={values?.event?.english}
              onBlur={handleBlur}
              placeholder="Event Details"
            />
            <Error field="event.english" />
          </div>

          <div className="row">
            <div className="col-5 offset-2 offset-sm-4 col-sm-4">
              <Button
                type="submit"
                className="btn form-button modaladdbutton"
                disabled={!formik.isValid || loading}
              >
                {loading ? "Saving..." : "Save"}
              </Button>
            </div>
            <div className="col-5 col-sm-4">
              <Button
                onClick={onClose}
                className="btn form-button modalcancelbutton"
              >
                Cancel
              </Button>
            </div>
          </div>
        </form>
      </ModalBody>
      {loading ? <Loader /> : ""}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state.themeChanger,
    token: state.auth.accessToken,
  };
};

export default compose(connect(mapStateToProps, { success, error }))(
  AddEditEvent
);
