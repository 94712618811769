import { connect } from "react-redux";
import React, { useState } from "react";
import { Button, ModalHeader, ModalBody } from "reactstrap";
import { compose } from "redux";
import NavigationActions from "redux/navigation/actions";
import Loader from "components/Loader";
import * as Yup from "yup";
import { useFormik } from "formik";
import { addGrahanDay, editGrahanDay } from "services/grahanDayServices";
import moment from "moment";
import Select from "react-select";
import DatePicker from "react-datepicker";
import DatePickerWrapper from "components/common/DatePickerWrapper";
import CustomTimePicker from "components/common/CustomTimePicker";

const { success, error } = NavigationActions;

const AddEditGrahan = ({ isEdit, editData, onClose, token, toggleRefresh }) => {
  
  const [loading, setLoading] = useState(false);

  const grahanOptions = [
    {
      label: "Chandra Grahan",
      value: "Chandra Grahan",
    },
    {
      label: "Surya Grahan",
      value: "Surya Grahan",
    },
  ];


  const validationSchema = Yup.object().shape({
    grahanType: Yup.string()
      .required("Grahan Type is required.")
      .oneOf(
        ["Surya Grahan", "Chandra Grahan"],
        "Paksha must be either 'Surya Grahan' or 'Chandra Grahan'."
      ),
    date: Yup.date()
      .required("Date is required.")
      .typeError("Date date must be a valid date."),
    startTime: Yup.string().required("Start time is required."),
    endTime: Yup.string().required("End time is required."),
  });
  

  const formik = useFormik({
    initialValues: isEdit
      ? {
          ...editData,
          date: new Date(editData.date),
          startTime: moment(editData.startTime, "hh:mm A"),
          endTime: moment(editData.endTime, "hh:mm A"),
        }
      : {
          date: new Date(),
          grahanType: "",
          startTime: "",
          endTime: "",
        },
    validationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const formatedDate = moment(values.date)
        const payload = {
          ...values,
          date: `${formatedDate.format("YYYY-MM-DD")}T00:00:00.000Z`,
          startTime: values.startTime.format("hh:mm A"),
          endTime: values.endTime.format("hh:mm A"),
        };

        const res = isEdit
          ? await editGrahanDay(token, values?._id, payload)
          : await addGrahanDay(token, payload);
        if (res?.success) {
          success(res?.message);
          onClose();
          toggleRefresh(true);
        } else {
          error(res?.message);
        }
      } catch (error) {
        // error(error.message);
        console.log("error", error);
      } finally {
        setLoading(false);
      }
    },
  });

  const {
    values,
    handleBlur,
    errors,
    touched,
    submitCount,
    isValid,
    handleSubmit,
  } = formik;

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };
  // console.log("values", values);
  console.log("errors",errors, );
  return (
    <>
      <ModalHeader toggle={() => onClose()}>
        {isEdit ? "Edit" : "Add"} Grahan
      </ModalHeader>
      <ModalBody>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>
              Grahan Type <span className="red">*</span>
            </label>
            <Select
              value={grahanOptions.find((i) => i.value === values.grahanType)}
              name="grahanType"
              options={grahanOptions}
              className="basic-multi-select w-100"
              classNamePrefix="select"
              onChange={(selected) => {
                formik.setFieldValue("grahanType", selected.value);
              }}
            />
            <Error field="grahanType" />
          </div>

          <div className="form-group">
            <label>
              Date <span className="red">*</span>
            </label>
            <DatePickerWrapper>
              <DatePicker
                peekPriviousMonth
                showMonthDropdown
                showYearDropdown
                name="date"
                id="date"
                selected={values.date}
                dateFormat="yyyy/MM/dd"
                className="form-control react-form-input"
                value={values.date}
                onChange={(d) => {
                  formik.setFieldValue("date", d);
                }}
                onBlur={handleBlur}
              />
            </DatePickerWrapper>
            <Error field="date" />
          </div>

          <div className="form-group">
            <label>Start Time</label>
            <CustomTimePicker
              value={
                formik.values?.startTime
                  ? moment(formik.values?.startTime)
                  : null
              }
              onChange={(value) => {
                formik.setFieldValue("startTime", value);
              }}
              name="startTime"
              disabled={false}
              sx={{ width: "100%" }}
            />
            <Error field="startTime" />
          </div>

          <div className="form-group">
            <label>End Time</label>
            <CustomTimePicker
              value={
                formik.values?.endTime ? moment(formik.values?.endTime) : null
              }
              onChange={(value) => {
                formik.setFieldValue("endTime", value);
              }}
              name="endTime"
              disabled={false}
              sx={{ width: "100%" }}
            />
            <Error field="endTime" />
          </div>

          <div className="row">
            <div className="col-5 offset-2 offset-sm-4 col-sm-4">
              <Button
                type="submit"
                className="btn form-button modaladdbutton"
                disabled={!isValid || loading}
              >
                {loading ? "Saving..." : "Save"}
              </Button>
            </div>
            <div className="col-5 col-sm-4">
              <Button
                onClick={onClose}
                className="btn form-button modalcancelbutton"
              >
                Cancel
              </Button>
            </div>
          </div>
        </form>
      </ModalBody>
      {loading ? <Loader /> : ""}
    </>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    token: state.auth.accessToken
  };
};

export default compose(
  connect(mapStateToProps, { success, error })
)(AddEditGrahan);
