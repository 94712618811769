import { connect } from "react-redux";
import React, { useState } from "react";
import { Button, ModalHeader, ModalBody } from "reactstrap";
import { compose } from "redux";
import NavigationActions from "redux/navigation/actions";
import Loader from "components/Loader";
import * as Yup from "yup";
import { useFormik } from "formik";
import { addPatotsav, editPatotsav } from "services/patotsavServices";

const { success, error } = NavigationActions;

const AddEditPatotsav = ({ isEdit, editData, onClose, token, toggleRefresh }) => {
  
  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    country: Yup.object().shape({
      english: Yup.string().required("Please enter the country name in English"),
      gujarati: Yup.string().required("Please enter the country name in Gujarati"),
    }),
    location: Yup.object().shape({
      english: Yup.string().required("Please enter the location name in English"),
      gujarati: Yup.string().required("Please enter the location name in Gujarati"),
    }),
    date: Yup.object().shape({
      english: Yup.string().required("Please enter the date in English"),
      gujarati: Yup.string().required("Please enter the date in Gujarati"),
    }),
  });
  

  const formik = useFormik({
    initialValues: isEdit
      ? editData
      : {
          country: {
            english: "",
            gujarati: "",
          },
          location: {
            english: "",
            gujarati: "",
          },
          date: {
            english: "",
            gujarati: "",
          },
        },
    validationSchema,
    onSubmit: async(values) => {
      try {
        setLoading(true);
        // console.log(values);

        const res = isEdit
          ? await editPatotsav(token, values?._id, values)
          : await addPatotsav(token, values);
        if (res?.success) {
          success(res?.message);
          onClose();
          toggleRefresh(true);
        } else {
          error(res?.message);
        }
      } catch (error) {
        error(error.message);
      } finally {
        setLoading(false);
      }
    },
  });

  const {
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount,
    isValid,
    handleSubmit,
  } = formik;

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  return (
    <>
      <ModalHeader toggle={() => onClose()}>
        {isEdit ? "Edit" : "Add"} Patotsav
      </ModalHeader>
      <ModalBody>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>
              Country Name English<span className="red">*</span>
            </label>
            <input
              type="text"
              className="form-control react-form-input"
              id="country.english"
              onChange={handleChange}
              value={values.country.english}
              onBlur={handleBlur}
              placeholder="Country Name"
            />
            <Error field="country.english" />
          </div>

          <div className="form-group">
            <label>
              Country Name Gujarati<span className="red">*</span>
            </label>
            <input
              type="text"
              className="form-control react-form-input"
              id="country.gujarati"
              onChange={handleChange}
              value={values.country.gujarati}
              onBlur={handleBlur}
              placeholder="Country Name"
            />
            <Error field="country.gujarati" />
          </div>
          
          <div className="form-group">
            <label>
              Patotsav Location English <span className="red">*</span>
            </label>
            <input
              type="text"
              className="form-control react-form-input"
              id="location.english"
              onChange={handleChange}
              value={values.location.english}
              onBlur={handleBlur}
              placeholder="Patotsav location"
            />
            <Error field="location.english" />
          </div>
          <div className="form-group">
            <label>
              Patotsav Location Gujarati<span className="red">*</span>
            </label>
            <input
              type="text"
              className="form-control react-form-input"
              id="location.gujarati"
              onChange={handleChange}
              value={values.location.gujarati}
              onBlur={handleBlur}
              placeholder="Patotsav location"
            />
            <Error field="location.gujarati" />
          </div>
          <div className="form-group">
            <label>
              Patotsav Date English<span className="red">*</span>
            </label>
            <input
              type="text"
              className="form-control react-form-input"
              id="date.english"
              onChange={handleChange}
              value={values.date.english}
              onBlur={handleBlur}
              placeholder="Patotsav Date"
            />
            <Error field="date.english" />
          </div>

          <div className="form-group">
            <label>
              Patotsav Date Gujarati<span className="red">*</span>
            </label>
            <input
              type="text"
              className="form-control react-form-input"
              id="date.gujarati"
              onChange={handleChange}
              value={values.date.gujarati}
              onBlur={handleBlur}
              placeholder="Patotsav Date"
            />
            <Error field="date.gujarati" />
          </div>
          <div className="row">
            <div className="col-5 offset-2 offset-sm-4 col-sm-4">
              <Button
                type="submit"
                className="btn form-button modaladdbutton"
                disabled={!isValid || loading}
              >
                {loading ? "Saving..." : "Save"}
              </Button>
            </div>
            <div className="col-5 col-sm-4">
              <Button
                onClick={onClose}
                className="btn form-button modalcancelbutton"
              >
                Cancel
              </Button>
            </div>
          </div>
        </form>
      </ModalBody>
      {loading ? <Loader /> : ""}
    </>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    token: state.auth.accessToken
  };
};

export default compose(
  connect(mapStateToProps, { success, error })
)(AddEditPatotsav);
