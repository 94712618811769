import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import { compose } from "redux";
import {
  addAppVersion,
  editAppVersion,
  getAppVersion,
} from "services/appVersionServices";
import * as Yup from "yup";
import NavigationActions from "redux/navigation/actions";
import Loader from "components/Loader";
import { useFormik } from "formik";

const { success, error } = NavigationActions;
const AppVersion = (props) => {
  const { token } = props;
  const [loading, setLoading] = useState(false);
  const [refresh, toggleRefresh] = useState(true);

  const validationSchema = Yup.object().shape({
    androidVersion: Yup.string()
      .required("Android Version Number is required.")
      .matches(/^\d+\.\d+\.\d+(\.\d+)?$/, "Invalid app version format"),
    iosVersion: Yup.string()
      .required("IOS Version Number is required.")
      .matches(/^\d+\.\d+\.\d+(\.\d+)?$/, "Invalid app version format"),
  });

  const formik = useFormik({
    initialValues: {
      androidVersion: "",
      iosVersion: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const payload = {
          androidVersion: values.androidVersion,
          iosVersion: values.iosVersion,
        };
        const res = values._id
          ? await editAppVersion(token, values?._id, payload)
          : await addAppVersion(token, payload);
        if (res?.success) {
          success(res?.message);
          toggleRefresh(true);
        } else {
          error(res?.message);
        }
      } catch (error) {
        // error(error.message);
        console.log("error", error);
      } finally {
        setLoading(false);
      }
    },
  });

  const { values, errors, touched, submitCount } = formik;

  const fetchAppVersion = async () => {
    await getAppVersion(token).then((res) => {
      if (res.success) {
        if(res?.data?.length > 0) {
          formik.setValues(res?.data[0] || []);
        }
      } else {
        error(res.message);
      }
    }).catch((err) => {
      error(err.message);
    }).finally(() => {
      toggleRefresh(false);
    });
  };
  useEffect(() => {
    refresh && fetchAppVersion();
    //eslint-disable-next-line
  }, [refresh]);

  const Error = (props) => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  return (
    <form onSubmit={formik.handleSubmit} className="w-25">
      <div className="form-group w-75">
        <label>
          Android App Version No<span className="red">*</span>
        </label>
        <input
          type="text"
          className="form-control react-form-input"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          name="androidVersion"
          id="androidVersion"
          value={values.androidVersion}
          placeholder="Enter App Version"
        />
      </div>
      <Error field="androidVersion" />
      <div className="form-group w-75">
        <label>
          IOS App Version No<span className="red">*</span>
        </label>
        <input
          type="text"
          className="form-control react-form-input"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          name="iosVersion"
          id="iosVersion"
          value={values.iosVersion}
          placeholder="Enter App Version"
        />
      </div>
      <Error field="iosVersion" />
      <div className="form-group w-75">
        <Button
          type="submit"
          className="btn form-button modaladdbutton"
          disabled={!formik.isValid || loading}
        >
          {loading ? "Saving..." : "Save"}
        </Button>
      </div>
      {loading ? <Loader /> : ""}
    </form>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state.themeChanger,
    token: state.auth.accessToken,
  };
};

export default compose(connect(mapStateToProps, { success, error }))(
  AppVersion
);
