import React, { useEffect, useState } from "react";
import { loginBack } from "helper/constant";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import AuthActions from "redux/auth/actions";
import enhancer from "./enhancer/LoginFormEnhancer";
import { checkApi, loginApi } from "services/authServices";
import NavigationActions from "redux/navigation/actions";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Loader from "components/Loader";

const { login, setUser } = AuthActions;

const {
  success,
  error,
  toggleOneTimeModal,
  fetching,
  toggleSubscriptionLoader
} = NavigationActions;

const Login = props => {
  const [togglePassword, setTogglePassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleLogin = async e => {
    e.preventDefault();
    let { values, handleSubmit, isValid } = props;

    if (isValid) {
      fetching();
      
      setLoading(true);
      await loginApi(values).then(data => {
        // console.log(data.data, "login data");
        if (data.success) {
          success(data.message);
          props.login(data.data);
          props.history.push("/events");
          let userData = data.data;
          // console.log(userData, "userData");
          props.setUser(userData);
          setLoading(false);
        } else {
          error(data.message);
          setLoading(false);
        }
      });
    }
    handleSubmit();
  };

  const {
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount,
    isValid,
    token
  } = props;

  const loginContainer = {
    backgroundImage: `url(${loginBack})`,
    backgroundPosition: "center center",
    backgroundSize: "cover",
    position: "fixed",
    overflow: "auto",
    top: 0,
    bottom: 0
  };

  const checkLogin = async () => {
    fetching();
    await checkApi(token).then(data => {
      if (data.success) {
        // check(data.data);
        // toggleSubscriptionLoader(false);
        success();
        props.history.push("/events");
      } else {
        error();
      }
    });
  };

  useEffect(() => {
    token !== null && checkLogin();
    // eslint-disable-next-line
  }, []);
  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  return (
    <div className="container-fluid" style={loginContainer}>
      <div className="form-container">
        <div className="login-icon">
          {/* <img src={iconDemo} alt="icon" height="100px" /> */}
        </div>
        <div className="login-title">Sign in to your account</div>
        <form className="pa-24" onSubmit={handleLogin}>
          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              className="form-control react-form-input"
              id="email"
              onChange={handleChange}
              value={values.email}
              onBlur={handleBlur}
              placeholder="Email"
            />
            <Error field="email" />
          </div>

          <div className="form-group">
            <label>Password</label>
            {/* <input
              type="password"
              className="form-control react-form-input"
              id="password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Password"
            /> */}
            <div className="input-group mb-3">
              <input
                type={togglePassword ? "text" : "password"}
                className="form-control react-form-input"
                id="password"
                onChange={handleChange}
                value={values.password}
                onBlur={handleBlur}
                placeholder="Confirm Password"
              />
              <div className="input-group-append">
                {togglePassword ? (
                  <VisibilityOff onClick={() => setTogglePassword(false)} />
                ) : (
                  <Visibility
                    onClick={() => {
                      setTogglePassword(true);
                      // console.log("clicked");
                    }}
                  />
                )}
              </div>
            </div>
            <Error field="password" />
          </div>

          <div className="form-check text-center mtb-16">
            <input
              type="checkbox"
              className="form-check-input"
              id="exampleCheck1"
            />
            <label className="form-check-label" htmlFor="exampleCheck1">
              Remember me
            </label>
          </div>
          {/* {console.log(isValid, "isValid", values)} */}

          <button
            type="submit"
            className="btn form-button siteenterbutton"
            disabled={isValid ? false : true}
          >
            Login
          </button>
          <div
            className="text-center link-label"
            onClick={() => props.history.push("/forgotPassword")}
          >
            Forgot Password ?
          </div>
        </form>
      </div>
      {loading && <Loader />}
    </div>
  );
};

const mapStateToProps = state => {
  // console.log(state);
  return {
    token: state.auth.accessToken
    // user: state.auth.user,
    // isFetching: state.navigation.isFetching
  };
};

export default compose(
  withRouter,
  enhancer,
  connect(mapStateToProps, {
    // check,
    login,
    success,
    error,
    toggleOneTimeModal,
    toggleSubscriptionLoader,
    fetching,
    setUser
  })
)(Login);
