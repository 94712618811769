import { api, handleResponse, handleError } from "./apiServices";
const baseUrl = "api/about";

export const getAboutUs = token =>
  api(token)
    .get(`${baseUrl}/getAboutUs`)
    .then(handleResponse)
    .catch(handleError);

export const addAboutUs = (token, data) =>
  api(token)
    .post(`${baseUrl}/addAboutUs`, data)
    .then(handleResponse)
    .catch(handleError);

export const editAboutUs = (token, id, data) =>
  api(token)
    .put(`${baseUrl}/editAboutUs/${id}`, data)
    .then(handleResponse)
    .catch(handleError);

export const deleteAboutUs = (token, id) =>
  api(token)
    .delete(`${baseUrl}/deleteAboutUs/${id}`)
    .then(handleResponse)
    .catch(handleError);