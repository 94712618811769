import { connect } from "react-redux";
import React, { useState, useMemo, useEffect, useCallback } from "react";
import { useTable, useSortBy, useFilters, usePagination } from "react-table";
import classnames from "classnames";
import ReactTableWrapper from "../../../components/reacttable/reacttbl.style";
import { compose } from "redux";
import NavigationActions from "redux/navigation/actions";
import { Modal } from "reactstrap";
import ConformationModaluser from "components/common/ConformationModalUser";
import Loader from "components/Loader";
import { deletePatotsav, getAllPatotsav } from "services/patotsavServices";
import AddEditPatotsav from "./AddEditPatotsav";
import ImportPatotsavs from "./ImportPatotsavs";

const HeaderComponent = props => {
  let classes = {
    "my-2": true,
    "mx-2": true,
    "-sort-asc": props.isSortedDesc === false,
    "-sort-desc": props.isSortedDesc === true
  };
  return <div className={classnames(classes)}>{props.title}</div>;
};

const { success, error } = NavigationActions;

const PatotsavList = props => {
  const { sidebarTheme, token } = props;
  const activeColor = {
    color: sidebarTheme.activeColor
  };

  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [refresh, toggleRefresh] = useState(true);
  const [openDeleteModal, toggleDeleteModalOpen] = useState();
  const [deleteId, setDeleteID] = useState("");
  const [patotsavList, setPatotsavList] = useState([]);
  const [filteredPatotsavList, setFilteredPatotsavList] = useState([]);
  const [search, setSearch] = useState();
  const [loading, setLoading] = useState(false);
  const [editData, setEditData] = useState({});
  const [showImportModal, setShowImportModal] = useState(false);


  const fetchAllPatotsav = useCallback(async () => {
    try {
      setLoading(true);
      const res = await getAllPatotsav(token);
      if (res?.success) {
        setPatotsavList(res?.data);
        setFilteredPatotsavList(res?.data);
      } else {
        error(res?.message);
      }
    } catch (error) {
      console.log(error, "error");
    } finally {
      setLoading(false);
      toggleRefresh(false);
    }

  }, [token]);


  useEffect(() => {
    refresh && fetchAllPatotsav();
  }, [refresh]);


  const deletePatotsavHandler = async () => {
    setLoading(true);
    try {
      const res = await deletePatotsav(token, deleteId)
      if (res?.success) {
        success(res?.message);
        toggleDeleteModalOpen(false);
        toggleRefresh(true);
        setDeleteID("");
      } else {
        error(res?.message);
      }
    } catch (error) {
      console.log(error, "error");
    } finally {
      setLoading(false);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: (tableInstance) => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Country Name"
            />
          );
        },
        placeholder: "country",
        accessor: "country",
        Cell: (tableInstance) => (
          <>
            <span className="text-capitalize">
              {tableInstance.row.original?.country?.english}
            </span>
            <br />
            <span className="text-capitalize">
              {tableInstance.row.original?.country?.gujarati}
            </span>
          </>
        ),
      },
      {
        Header: (tableInstance) => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Location"
            />
          );
        },
        placeholder: "Location",
        accessor: "location",
        Cell: (tableInstance) => (
          <>
            <span className="text-capitalize">
              {tableInstance.row.original?.location?.english}
            </span>
            <br />

            <span className="text-capitalize">
              {tableInstance.row.original?.location?.gujarati}
            </span>
          </>
        ),
      },
      {
        Header: (tableInstance) => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Date"
            />
          );
        },
        placeholder: "Date",
        accessor: "date",
        Cell: (tableInstance) => (
          <>
            <span className="text-capitalize">
              {tableInstance.row.original?.date?.english}
            </span>
            <br />

            <span className="text-capitalize">
              {tableInstance.row.original?.date?.gujarati}
            </span>
          </>
        ),
      },
      {
        Header: (tableInstance) => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Action"
            />
          );
        },
        accessor: "id",
        disableSortBy: true,
        disableFilters: true,
        Cell: (tableInstance) => {
          return (
            <div className="react-action-class">
              <button
                className="react-table-edit-button"
                onClick={() => {
                  setModal(true);
                  setIsEdit(true);
                  setEditData(tableInstance.row.original);
                }}
              >
                <i className="fas fa-edit" />
              </button>
              <button
                className="react-table-delete-button"
                onClick={() => {
                  toggleDeleteModalOpen(true);
                  setDeleteID(tableInstance.row.original?._id);
                }}
              >
                <i className="fas fa-trash" />
              </button>
            </div>
          );
        },
      },
    ],
    [patotsavList]
  );

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    page,
    headerGroups,
  } = useTable(
    {
      data: filteredPatotsavList,
      columns: columns,
      initialState: {
        pageSize: filteredPatotsavList?.length ||  patotsavList?.length || 10000,
        pageIndex: 0
      }
    },
    useFilters,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    if (patotsavList?.length>0) {
      const filtered = patotsavList.filter(
        (item) =>
          item?.country?.english
            ?.toLowerCase()
            ?.includes(search?.toLowerCase()) ||
          item?.country?.gujarati
            ?.toLowerCase()
            ?.includes(search?.toLowerCase()) ||
          item?.location?.english
            ?.toLowerCase()
            ?.includes(search?.toLowerCase()) ||
          item?.location?.gujarati
            ?.toLowerCase()
            ?.includes(search?.toLowerCase())
      );
          setFilteredPatotsavList(filtered);
        }
  }, [search, patotsavList]);

  return (
    <>
      <div>
        <div className="plr-15">
          <div className="mtb-30 theme-color">
            <div className="row">
              <div className="col-6 col-md-8">
                <div className="introduction" style={activeColor}>
                  Patotsav
                </div>
              </div>
              <div className="col-6 col-md-4">
                <div className="d-flex justify-content-center align-items-center">
                  <button
                    className="c-secondary btn btn-secondary w-100"
                    onClick={() => {
                      setShowImportModal(true);
                    }}
                  >
                    Import
                  </button>
                  <button
                    type="submit"
                    className="c-primary btn btn-secondary w-100 addbtncolor ml-2 text-nowrap"
                    onClick={() => {
                      setIsEdit(false);
                      setModal(true);
                    }}
                  >
                    Add Patotsav
                  </button>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-4">
                <input
                  className="form-control react-form-input mt-2"
                  type="text"
                  placeholder="Search Patotsav"
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
              </div>
              <div className="col-12">
                {
                  <ReactTableWrapper {...props}>
                    <div className="table-container text-left overflow-auto mt-4">
                      <table
                        border={1}
                        className="custom-react-table-theme-class"
                        {...getTableProps()}
                      >
                        <thead>
                          {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                              {headerGroup.headers.map((header) => (
                                <th
                                  {...header.getHeaderProps(
                                    header.getSortByToggleProps()
                                  )}
                                >
                                  <div>{header.render("Header")}</div>
                                </th>
                              ))}
                            </tr>
                          ))}
                        </thead>
                        {loading ? (
                          <tbody>
                            <tr>
                              <td>
                                <Loader />
                              </td>
                            </tr>
                          </tbody>
                        ) : (
                          <tbody {...getTableBodyProps()}>
                            {patotsavList.length > 0 ? (
                              page.map((row) => {
                                prepareRow(row);
                                return (
                                  <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => (
                                      <td {...cell.getCellProps()}>
                                        {cell.render("Cell")}
                                      </td>
                                    ))}
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                {
                                  <td className="mt-4 text-left font-weight-bold">
                                    <h5>No data found</h5>
                                  </td>
                                }
                              </tr>
                            )}
                          </tbody>
                        )}
                      </table>
                    </div>
                  </ReactTableWrapper>
                }
              </div>
            </div>
          </div>
        </div>
        <Modal
          isOpen={modal}
          backdrop={true}
          centered={true}
          unmountOnClose={true}
        >
          {modal && (
            <AddEditPatotsav
              isEdit={isEdit}
              token={token}
              editData={editData}
              onClose={() => {
                setModal(false);
                setIsEdit(false);
                setEditData(null);
              }}
              toggleRefresh={(e) => toggleRefresh(e)}
            />
          )}
        </Modal>

        <Modal
          isOpen={showImportModal}
          backdrop={true}
          centered={true}
          unmountOnClose={true}
        >
          {showImportModal && (
            <ImportPatotsavs
              token={token}
              onClose={(isUploaded) => {
                setShowImportModal(false);
                if (isUploaded) {
                  toggleRefresh(true);
                }
              }}
            />
          )}
        </Modal>

        <Modal isOpen={openDeleteModal} backdrop={true} unmountOnClose={true}>
          {openDeleteModal && (
            <ConformationModaluser
              isOpen={openDeleteModal}
              onClose={() => toggleDeleteModalOpen(false)}
              confirmText={"Delete"}
              message={"Are you sure you want to delete?"}
              handleConfirm={() => deletePatotsavHandler()}
            />
          )}
        </Modal>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    token: state.auth.accessToken
  };
};

export default compose(
  // enhancer,
  connect(mapStateToProps, { success, error })
)(PatotsavList);
