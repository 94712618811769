import { connect } from "react-redux";
import React, { useState } from "react";
import { Button, ModalHeader, ModalBody } from "reactstrap";
import { compose } from "redux";
import NavigationActions from "redux/navigation/actions";
import Loader from "components/Loader";
import * as Yup from "yup";
import { useFormik } from "formik";
import { addAboutUs, editAboutUs } from "services/aboutServices";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const { success, error } = NavigationActions;

const AddEditAbout = ({ isEdit, editData, onClose, token, toggleRefresh }) => {
  
  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    image: Yup.string().required("Logo is required."),
    heading: Yup.string().required("Heading is required."),
    description: Yup.string().required("Description is required."),
    socialLinks: 
      Yup.object().shape({
        facebook: Yup.string().optional(),
        youtube: Yup.string().optional(),
        instagram: Yup.string().optional(),
        linkedin: Yup.string().optional(),
        twitter: Yup.string().optional(),
      })
  });
  

  const formik = useFormik({
    initialValues: isEdit
      ? { ...editData, image: editData?.imageLogo ? editData?.imageLogo : "" }
      : {
          image: "",
          heading: "",
          description: "",
          socialLinks: {
            facebook: "",
            youtube: "",
            instagram: "",
            linkedin: "",
            twitter: "",
          },
        },
    validationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);

        const payload = {
          ...values,
        };

        // convert payload to formData
        const formData = new FormData();
        for (const key in payload) {
          if (payload.hasOwnProperty(key)) {
            if (key === "socialLinks") {
              // console.log("socialLinks", payload[key]);
              formData.append(key, JSON.stringify(payload[key]));
            } else {
              formData.append(key, payload[key]);
            }
          }
        }

        // console.log("Submitting payload:", payload);

        const res = isEdit
          ? await editAboutUs(token, values?._id, formData)
          : await addAboutUs(token, formData);
        if (res?.success) {
          success(res?.message);
          onClose();
          toggleRefresh(true);
        } else {
          error(res?.message);
        }
      } catch (error) {
        error(error.message);
      } finally {
        setLoading(false);
      }
    },
  });

  const {
    values,
    handleBlur,
    errors,
    touched,
    submitCount,
    isValid,
    handleSubmit,
  } = formik;

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  return (
    <>
      <ModalHeader toggle={() => onClose()}>
        {isEdit ? "Edit" : "Add"} Grahan
      </ModalHeader>
      <ModalBody>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>
              Image <span className="error-msg">*</span>
            </label>
            <br />

            <input
              type="file"
              id="upload"
              className="form-control react-form-input"
              accept=".jpg,.png,.jpeg,.svg"
              onChange={(e) => {
                if (e.target.files[0]) {
                  formik.setFieldValue("image", e.target.files[0]);
                } else {
                  formik.setFieldValue("image", "");
                }
              }}
              onBlur={handleBlur}
            />
            {values?.image && (
              <>
                <br />
                <img
                  src={
                    values.image instanceof Blob
                      ? URL.createObjectURL(values.image)
                      : `${process.env.REACT_APP_UPLOAD_DIR}${values?.image}`
                  }
                  className="profile-img"
                  alt="no"
                  width={200}
                />
                <br />
              </>
            )}
            <Error field="image" />
          </div>

          <div className="form-group">
            <label>
              Heading <span className="error-msg">*</span>
            </label>
            <textarea
              className="form-control react-form-input"
              id="heading"
              rows={3}
              onChange={formik.handleChange}
              value={values.heading}
              onBlur={handleBlur}
              placeholder="Event Details"
            />
            <Error field="heading" />
          </div>

          <div className="form-group">
            <label className="mb-2">
              Description <span className="error-msg">*</span>{" "}
            </label>
            <ReactQuill
              theme="snow"
              value={values.description}
              modules={AddEditAbout.modules}
              formats={AddEditAbout.formats}
              className="description-quill"
              placeholder="Enter description"
              onChange={(content, delta, source, editor) => {
                if (editor.getLength() <= 1) {
                  formik.setFieldValue("description", "");
                  formik.setFieldError(
                    "description",
                    "Description is required"
                  );
                } else {
                  formik.setFieldValue("description", content, false);
                }
              }}
              onBlur={(previousRange, source, editor) => {
                if (editor.getLength() <= 1) {
                  formik.setFieldValue("description", "");
                  formik.setFieldError(
                    "description",
                    "Description is required"
                  );
                } else {
                  formik.setFieldValue("description", editor.getHTML());
                }
                if (!values.description) {
                  formik.setFieldTouched("description", true);
                }
              }}
            />
            <Error field="description" />
          </div>

          <div className="form-group">
            <label>Facebook Link</label>
            <input
              type="text"
              className="form-control react-form-input"
              id="socialLinks.facebook"
              onChange={formik.handleChange}
              value={values.socialLinks.facebook}
              onBlur={handleBlur}
              placeholder="Facebook Link"
            />
            <Error field="socialLinks.facebook" />
          </div>

          <div className="form-group">
            <label>Twitter Link</label>
            <input
              type="text"
              className="form-control react-form-input"
              id="socialLinks.twitter"
              onChange={formik.handleChange}
              value={values.socialLinks.twitter}
              onBlur={handleBlur}
              placeholder="Twitter Link"
            />
            <Error field="socialLinks.twitter" />
          </div>

          <div className="form-group">
            <label>Instagram Link</label>
            <input
              type="text"
              className="form-control react-form-input"
              id="socialLinks.instagram"
              onChange={formik.handleChange}
              value={values.socialLinks.instagram}
              onBlur={handleBlur}
              placeholder="Instagram Link"
            />
            <Error field="socialLinks.instagram" />
          </div>

          <div className="form-group">
            <label>LinkedIn Link</label>
            <input
              type="text"
              className="form-control react-form-input"
              id="socialLinks.linkedin"
              onChange={formik.handleChange}
              value={values.socialLinks.linkedin}
              onBlur={handleBlur}
              placeholder="LinkedIn Link"
            />
            <Error field="socialLinks.linkedin" />
          </div>

          <div className="form-group">
            <label>Youtube Link</label>
            <input
              type="text"
              className="form-control react-form-input"
              id="socialLinks.youtube"
              onChange={formik.handleChange}
              value={values.socialLinks.youtube}
              onBlur={handleBlur}
              placeholder="Youtube Link"
            />
            <Error field="socialLinks.youtube" />
          </div>

          <div className="row">
            <div className="col-5 offset-2 offset-sm-4 col-sm-4">
              <Button
                type="submit"
                className="btn form-button modaladdbutton"
                disabled={!isValid || loading}
              >
                {loading ? "Saving..." : "Save"}
              </Button>
            </div>
            <div className="col-5 col-sm-4">
              <Button
                onClick={onClose}
                className="btn form-button modalcancelbutton"
              >
                Cancel
              </Button>
            </div>
          </div>
        </form>
      </ModalBody>
      {loading ? <Loader /> : ""}
    </>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    token: state.auth.accessToken
  };
};

export default compose(
  connect(mapStateToProps, { success, error })
)(AddEditAbout);
