import { connect } from "react-redux";
import React, { useState, useMemo, useEffect, useCallback } from "react";
import { useTable, useSortBy, useFilters, usePagination } from "react-table";
import Pagination from "@mui/material/Pagination";

import ReactTableWrapper from "../../../components/reacttable/reacttbl.style";
import { compose } from "redux";
import NavigationActions from "redux/navigation/actions";
import { Modal } from "reactstrap";
import ConformationModaluser from "components/common/ConformationModalUser";
import Loader from "components/Loader";
import { deletePanchangDay, getAllPanchangDays } from "services/panchangDayServices";
import AddEditEvent from "./AddEditEvent";
// import { TITHI_NAMES } from "helper/constant";
import ImportEvents from "./ImportEvents";
import moment from "moment";
const { success, error } = NavigationActions;

const EventList = props => {
  const { sidebarTheme, token } = props;
  const activeColor = useMemo(
    () => ({ color: sidebarTheme.activeColor }),
    [sidebarTheme.activeColor]
  );

  const [modal, setModal] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [refresh, toggleRefresh] = useState(true);
  const [openDeleteModal, toggleDeleteModalOpen] = useState(false);
  const [deleteId, setDeleteID] = useState("");
  const [panchangDayList, setPanchangDayList] = useState([]);
  const [search, setSearch] = useState();
  const [loading, setLoading] = useState(false);
  const [editData, setEditData] = useState({});
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const formatSearchDate = searchTerm => {
    const validDate = moment(searchTerm, ["DD-MM-YYYY", "DD/MM/YYYY", "YYYY-MM-DD"], true);
    return validDate.isValid() ? `${validDate.format("YYYY-MM-DD")}T00:00:00.000Z` : searchTerm;
  };
  // Fetch all Panchang days with memoized dependencies
  const fetchAllPanchangDays = useCallback(async () => {
    try {
      setLoading(true);

      const payload = {
        page: currentPage,
        limit: 10,
        search: formatSearchDate(search),
      };

      const res = await getAllPanchangDays(token, payload);
      if (res?.success) {
        setPanchangDayList(res?.data?.docs);
        setTotalPages(res?.data?.totalPages);
      } else {
        error(res?.message);
      }
    } catch (err) {
      console.error("Error fetching Panchang days:", err);
    } finally {
      setLoading(false);
      toggleRefresh(false);
    }
  }, [token, currentPage, search]);

  // Handle delete action with memoized function
  const deletePanchangDayHandler = useCallback(async () => {
    try {
      setLoading(true);
      const res = await deletePanchangDay(token, deleteId);
      if (res?.success) {
        success(res?.message);
        toggleDeleteModalOpen(false);
        toggleRefresh(true);
        setDeleteID("");
        setCurrentPage(1);
      } else {
        error(res?.message);
      }
    } catch (err) {
      console.error("Error deleting Panchang day:", err);
    } finally {
      setLoading(false);
    }
  }, [token, deleteId]);

  // Columns for react-table
  const columns = useMemo(
    () => [
      {
        Header: "Image",
        accessor: "image",
        Cell: ({ row }) => (
          <span>
            {row.original?.image && (
              <img
                src={`${process.env.REACT_APP_UPLOAD_DIR}${row.original?.image}`}
                className="profile-img"
                alt={row.original?.event}
                width={60}
              />
            )}
          </span>
        ),
      },
      {
        Header: "Gregorian Date",
        accessor: "gregorianDate",
        Cell: ({ row }) => (
          <span className="text-capitalize">
            {row.original?.gregorianDate
              ? row.original?.gregorianDate.split("T")[0]
              : ""}
          </span>
        ),
      },
      {
        Header: "Tithi",
        accessor: "tithi",
        Cell: ({ row }) => (
          <span className="text-capitalize">
            {row.original?.apiData?.tithi?.length > 0 && (
              <>
                {row.original?.apiData?.tithi.map((tithi, index) => (
                  <span key={index} className="w-fit-content">
                    {tithi.name} - {tithi.paksha}
                    <br />
                    {moment(tithi.start)?.format(
                      "DD MMM YY hh:mm A"
                    )} to {moment(tithi.end)?.format("DD MMM YY hh:mm A")}
                    <br />
                  </span>
                ))}
              </>
            )}
          </span>
        ),
      },
      {
        Header: "Event",
        accessor: "event",
        Cell: ({ row }) => (
          <>
            <span
              className="d-inline-block text-truncate text-capitalize"
              style={{ maxWidth: "300px" }}
            >
              {row.original?.event?.gujarati
                ? row.original?.event?.gujarati
                : ""}
            </span>
            <br />
            <span
              className="d-inline-block text-truncate text-capitalize"
              style={{ maxWidth: "300px" }}
            >
              {row.original?.event?.english ? row.original?.event?.english : ""}
            </span>
          </>
        ),
      },
      {
        Header: "Action",
        accessor: "id",
        disableSortBy: true,
        Cell: ({ row }) => (
          <div className="react-action-class">
            <button
              className="react-table-edit-button"
              onClick={() => {
                setModal(true);
                setIsEdit(true);
                setEditData(row.original);
              }}
            >
              <i className="fas fa-edit" />
            </button>
            <button
              className="react-table-delete-button"
              onClick={() => {
                toggleDeleteModalOpen(true);
                setDeleteID(row.original?._id);
              }}
            >
              <i className="fas fa-trash" />
            </button>
          </div>
        ),
      },
    ],
    []
  );

  // React Table hooks
  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    page,
    headerGroups,
  } = useTable(
    {
      data: panchangDayList,
      columns,
      initialState: { pageSize: 10, pageIndex: 0 },
    },
    useFilters,
    useSortBy,
    usePagination
  );

  // Effects
  useEffect(() => {
    refresh && fetchAllPanchangDays();
  }, [refresh, fetchAllPanchangDays]);

  useEffect(() => {
    if (search !== undefined) {
      const timeoutId = setTimeout(() => fetchAllPanchangDays(), 1500);
      return () => clearTimeout(timeoutId);
    }
  }, [search, fetchAllPanchangDays]);

  return (
    <>
      <div>
        <div className="plr-15">
          <div className="mtb-30 theme-color">
            <div className="row">
              <div className="col-6 col-md-8">
                <div className="introduction" style={activeColor}>
                  Events
                </div>
              </div>
              <div className="col-6 col-md-4">
                <div className="d-flex justify-content-center align-items-center">
                  <button
                    className="c-secondary btn btn-secondary w-100"
                    onClick={() => {
                      setShowImportModal(true);
                    }}
                  >
                    Import
                  </button>

                  <button
                    className="c-primary btn btn-secondary w-100 addbtncolor ml-2 text-nowrap"
                    onClick={() => {
                      setIsEdit(false);
                      setModal(true);
                    }}
                  >
                    Add New Event
                  </button>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-4">
                <input
                  className="form-control react-form-input mt-2"
                  type="text"
                  placeholder="Search Events"
                  onChange={(e) => {
                    setSearch(e.target.value);
                    setCurrentPage(1);
                  }}
                />
              </div>
              <div className="col-12">
                {
                  <ReactTableWrapper {...props}>
                    <div className="table-container text-left overflow-auto mt-4">
                      <table
                        border={1}
                        className="custom-react-table-theme-class"
                        {...getTableProps()}
                      >
                        <thead>
                          {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                              {headerGroup.headers.map((header) => (
                                <th
                                  {...header.getHeaderProps(
                                    header.getSortByToggleProps()
                                  )}
                                >
                                  <div>{header.render("Header")}</div>
                                </th>
                              ))}
                            </tr>
                          ))}
                        </thead>
                        {loading ? (
                          <tbody>
                            <tr>
                              <td>
                                <Loader />
                              </td>
                            </tr>
                          </tbody>
                        ) : (
                          <tbody {...getTableBodyProps()}>
                            {panchangDayList.length > 0 ? (
                              page.map((row) => {
                                prepareRow(row);
                                return (
                                  <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => (
                                      <td {...cell.getCellProps()} className="">
                                        {cell.render("Cell")}
                                      </td>
                                    ))}
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                {
                                  <td className="mt-4 text-left font-weight-bold">
                                    <h5>No data found</h5>
                                  </td>
                                }
                              </tr>
                            )}
                          </tbody>
                        )}
                      </table>
                    </div>
                    <div className="float-right">
                      <Pagination
                        page={currentPage}
                        count={totalPages}
                        onChange={(e, val) => {
                          setCurrentPage(val);
                          toggleRefresh(true);
                        }}
                      />
                    </div>
                  </ReactTableWrapper>
                }
              </div>
            </div>
          </div>
        </div>
        <Modal
          isOpen={modal}
          backdrop={true}
          centered={true}
          unmountOnClose={true}
          
        >
          {modal && (
            <AddEditEvent
              isEdit={isEdit}
              token={token}
              editData={editData}
              onClose={() => {
                setModal(false);
                setIsEdit(false);
                setEditData(null);
              }}
              toggleRefresh={(e) => toggleRefresh(e)}
            />
          )}
        </Modal>

        <Modal
          isOpen={showImportModal}
          backdrop={true}
          centered={true}
          unmountOnClose={true}
        >
          {showImportModal && (
            <ImportEvents
              token={token}
              editData={editData}
              onClose={(isUploaded) => {
                setShowImportModal(false);
                if (isUploaded) {
                  toggleRefresh(true);
                }
              }}
            />
          )}
        </Modal>

        <Modal isOpen={openDeleteModal} backdrop={true} unmountOnClose={true}>
          {openDeleteModal && (
            <ConformationModaluser
              isOpen={openDeleteModal}
              onClose={() => toggleDeleteModalOpen(false)}
              confirmText={"Delete"}
              message={"Are you sure you want to delete?"}
              handleConfirm={() => deletePanchangDayHandler()}
            />
          )}
        </Modal>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    token: state.auth.accessToken
  };
};

export default compose(
  connect(mapStateToProps, { success, error })
)(EventList);
