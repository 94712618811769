import { api, handleResponse, handleError } from "./apiServices";
const baseUrl = '/api/appVersion';
export const addAppVersion = (token, data) =>
  api(token)
    .post(`${baseUrl}/add`, data)
    .then(handleResponse)
    .catch(handleError);

export const editAppVersion = (token, id, data) =>
  api(token)
    .put(`${baseUrl}/edit/${id}`, data)
    .then(handleResponse)
    .catch(handleError);

export const getAppVersion = token =>
  api(token)
    .get(`${baseUrl}/get`)
    .then(handleResponse)
    .catch(handleError);
