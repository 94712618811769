// import ServiceList from "views/pages/services/ServiceList";
import PatotsavList from "views/pages/patotsav";
import EventList from "views/pages/event";
import GrahanList from "views/pages/grahan";
import AboutPage from "views/pages/about";
import AppVersion from "views/pages/app-version";
import EditProfile from "views/pages/admin/EditProfile";
import ChangePassword from "views/pages/admin/ChangePassword";
// import Dashboard from "views/app/dashboards";

const dashboardRoutes = [
  // { path: "/dashboard", component: Dashboard },
  // { path: "/services", component: ServiceList },
  { path: "/events", component: EventList },
  { path: "/patotsav", component: PatotsavList },
  { path: "/about", component: AboutPage },
  { path: "/grahan", component: GrahanList },
  { path: "/change-password", component: ChangePassword },
  { path: "/profile", component: EditProfile },
  { path: "/app-version", component: AppVersion },
];
export default dashboardRoutes;
