import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import { compose } from "redux";
import authActions from "redux/auth/actions";
import { history } from "./redux/store";
import layoutRoutes from "./routes/index.jsx";
import navigationAction from "redux/navigation/actions";
import { checkApi } from "services/authServices";

const { check, logout, setUser } = authActions;
const { success, error, getNotificationData } = navigationAction;

const Routes = props => {
  const {
    token,
    check,
    user_id,
    getNotificationData
  } = props;

  const location = useLocation();

  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(true);
  var excludePath = [
    "login",
    "register",
    "forgotPassword",
    "admin",
    "user",
    "request-accept",
    "login_as"
  ];

  const checkLogin = async () => {
    const data = { id: user_id, token: token };
    await checkApi(token, data).then(data => {
      if (data.success) {
        check(data.data);
        setUser(data.data);
        success();
      } else {
        logout(token);
        error();
      }
    });
  };

  useEffect(() => {
    if (!excludePath.includes(location.pathname.split("/")[1])) {
      checkLogin();
      getNotificationData(token);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line
  }, []);

  return (

    <Switch>
      <Route
        exact
        path="/"
        render={() =>
          token ? <Redirect to="/events" /> : <Redirect to="/login" />
        }
      />
      {layoutRoutes.map((prop, key) => {
        return (
          <Route
            path={prop.path}
            component={prop.component}
            key={key}
            history={history}
          />
        );
      })}
    </Switch>
    // </Router>
  );
};

const mapStateToProps = state => {
  // console.log(state, "check107");
  return {
    token: state.auth.accessToken,
    user: state.auth.user,
    user_id: state.auth.user_id
  };
};

export default compose(
  connect(mapStateToProps, {
    check,
    logout,
    success,
    error,
    getNotificationData,
    setUser
  })
)(Routes);
