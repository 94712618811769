import React from "react";

const PanchangPreviewTable = ({ data }) => {
  return (
    <div className="table-responsive">
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Country</th>
            <th>Location</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              <td className="text-nowrap">
                {row?.country?.english}
                <br />
                {row?.country?.gujarati}
              </td>
              <td className="text-nowrap">
                {row?.location?.english}
                <br />
                {row?.location?.gujarati}
              </td>

              <td className="text-nowrap">
                {row?.date?.english}
                <br />
                {row?.date?.gujarati}
              </td>
              
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PanchangPreviewTable;
