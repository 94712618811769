import React from "react";

const PanchangPreviewTable = ({ data }) => {
  return (
    <div className="table-responsive">
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Date</th>
            {/* <th>Vikram Samvat Year</th>
            <th>Vikram Samvat Month</th>
            <th>Tithi</th>
            <th>Paksha</th>
            <th>Ritu</th>
            <th>Sunrise</th>
            <th>Sunset</th>
            <th>Nakshatra</th>
            <th>Moon Rashi</th>
            <th>Moon Rashi Time</th> */}
            {/* <th>Image</th> */}
            <th>Event (English)</th>
            <th>Event (Gujarati)</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              <td className="text-nowrap">{row.gregorianDate}</td>
              {/* <td>{row.vikramSamvatYear}</td>
              <td>{row.vikramSamvatMonth}</td>
              <td>{row.tithi}</td>
              <td>{row.paksha}</td>
              <td>{row.ritu}</td>
              <td>{row.sunrise}</td>
              <td>{row.sunset}</td>
              <td>{row.nakshatra}</td>
              <td>{row?.moonRashi}</td>
              <td>{row?.moonRashiTime}</td> */}
              <td>{row?.event?.english || "-"}</td>
              <td>{row?.event?.gujarati || "-"}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PanchangPreviewTable;
