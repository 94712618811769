// import { NAKSHATRA_NAMES, RASHI_NAMES, RITU_NAMES, TITHI_NAMES, VIKRAM_SAMVAT_MONTHS } from "helper/constant";
import * as Yup from "yup";
const panchangDaySchema = Yup.object().shape({
  gregorianDate: Yup.string()
    .required("Gregorian date is required.")
    .typeError("Gregorian date must be a valid date."),
  //   vikramSamvatYear: Yup.number()
  //     .required("Vikram Samvat year is required.")
  //     .positive("Vikram Samvat year must be a positive number.")
  //     .integer("Vikram Samvat year must be an integer."),
  //   vikramSamvatMonth: Yup.string()
  //     .required("Vikram Samvat month is required.")
  //     .oneOf(VIKRAM_SAMVAT_MONTHS, "Invalid Vikram Samvat month."),
  //   tithi: Yup.string()
  //     .required("Tithi is required.")
  //     .oneOf(Object.keys(TITHI_NAMES), "Invalid Tithi."),
  //   paksha: Yup.string()
  //     .required("Paksha is required.")
  //     .oneOf(
  //       ["Shukla", "Krishna"],
  //       "Paksha must be either 'Shukla' or 'Krishna'."
  //     ),
  //   sunrise: Yup.string().required("Sunrise time is required."),
  //   sunset: Yup.string().required("Sunset time is required."),
  //   ritu: Yup.string()
  //   .nullable()
  //   .test(
  //     "is-valid-ritu",
  //     "Invalid Ritu.",
  //     (value) => !value || RITU_NAMES.includes(value)
  //   ),
  // nakshatra: Yup.string()
  //   .nullable()
  //   .test(
  //     "is-valid-nakshatra",
  //     "Invalid Nakshatra.",
  //     (value) => !value || NAKSHATRA_NAMES.includes(value)
  //   ),

  //   moonRashi: Yup.string()
  //     .nullable()
  //     .test(
  //       "is-valid-moonRashi",
  //       "Invalid Rashi.",
  //       (value) => !value || RASHI_NAMES.includes(value)
  //     ),
  //   moonRashiTime: Yup.string(),
  //   image: Yup.string().optional(),
  event: Yup.object().shape({
    english: Yup.string()
      .required("Event is required.")
      .max(255, "Event description must not exceed 255 characters."),
    gujarati: Yup.string()
      .required("Event is required.")
      .max(255, "Event description must not exceed 255 characters."),
  }),
});

  export default panchangDaySchema;