import { api, handleResponse, handleError } from "./apiServices";
const baseUrl = "api/panchangDay";

export const getAllPanchangDays = (token, data) =>
  api(token)
    .post(`${baseUrl}/getList`,data)
    .then(handleResponse)
    .catch(handleError);

export const addPanchangDay = (token, data) =>
  api(token)
    .post(`${baseUrl}/add`, data)
    .then(handleResponse)
    .catch(handleError);

export const editPanchangDay = (token, id, data) =>
  api(token)
    .put(`${baseUrl}/edit/${id}`, data)
    .then(handleResponse)
    .catch(handleError);

export const deletePanchangDay = (token, id) =>
  api(token)
    .delete(`${baseUrl}/delete/${id}`)
    .then(handleResponse)
    .catch(handleError);

export const addBulkPanchangDay = (token, data) =>
  api(token)
    .post(`${baseUrl}/bulkUpload`, data)
    .then(handleResponse)
    .catch(handleError);